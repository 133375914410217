import * as THREE from "three";
import Experience from "../../experience";

const DEFAULT_BOX = 1;

export default class Belt 
{
    constructor( modelList )
    {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.debug = this.experience.debug;
        this.models = [];
        this.modelList = modelList;

        this.shown = true;

        this.currentBox = DEFAULT_BOX;

        this.setModels();
        this.setAnimation();

    }

    setModels(){

        this.modelList.forEach(
            (mdl) => {
                let model = this.resources.items[mdl].scene;
                model.name = mdl;
                model.resource = this.resources.items[mdl];
                model.scale.set(1,1,1);
                if (mdl === 'plasticBoxModel' || mdl === 'micropitchCans') model.scale.set(0,0,0);
                model.position.set(0,0,0);

                if( mdl === 'plasticBoxModel' ){
                    model.position.set(0,0.003,0);
                }

                if (  mdl.includes('proxy') ) {
                    model.position.set(0,0.001,0)
                    model.scale.set(0,0,0);
                };
                
                this.scene.add( model );

                model.traverse((child) => {
                    if ( child instanceof THREE.Mesh ) {
                        child.castShadow = true;
                    }
                })

                this.models.push(model);
            })

    }

    setAnimation(){
        this.models.filter((m) => m.resource?.animations.length && m.resource?.animations.length > 0 ).forEach((model) => {
            
            model.animation = {}
            model.animation.mixer = new THREE.AnimationMixer(model)
            model.animation.actions = {}

            model.animation.actions.idle = model.animation.mixer.clipAction(model.resource.animations[0])

            model.animation.actions.current = model.animation.actions.idle;
            model.animation.actions.current.play();

            model.animation.play = (name) => {
            const newAction = model.animation.actions[name];
            const oldAction = model.animation.actions.current;

            newAction.reset();
            newAction.play();
            newAction.crossFadeFrom(oldAction, 1);

            model.animation.actions.current = newAction;
        }
        })
    }

    toggle(){
        
        this.models.forEach((model, i) => {
            if( !this.shown ) {
                if( !this.beltsHidden || !model.name.includes('proxy')) {
                    model.scale.set(1,1,1)
                }
                if((this.currentBox !== i && (i === 1 || i === 2)) ) { model.scale.set(0,0,0) }
                if(model.name.includes('proxy1') && this.belt1Hidden) { model.scale.set(0,0,0)}
                if(model.name.includes('proxy2') && this.belt2Hidden) { model.scale.set(0,0,0)}

            }
            if( this.shown ) {
                model.scale.set(0,0,0);
            }

        })

        this.shown = !this.shown;

        if( this.shown ) {
            if ( this.currentBox === 1 ) {
                this.models[1].scale.set(1,1,1);
                this.models[2].scale.set(0,0,0);
            }
            if ( this.currentBox === 2 ) {
                this.models[2].scale.set(1,1,1);
                this.models[1].scale.set(0,0,0);
            }
        }

        if( this.annotations ) { this.annotations.toggle();}
        
        
    }

    belt1Hidden = true;
    belt2Hidden = true;

    toggleBelt1(){ 
        this.models.forEach((m) => { 
            if(m.name.includes("proxy1")) {
                this.belt1Hidden ? m.scale.set(1,1,1) : m.scale.set(0,0,0)
            } })
            this.belt1Hidden = !this.belt1Hidden
    }
    
    toggleBelt2(){ 
        this.models.forEach((m) => { 
            if(m.name.includes("proxy2")) {
                this.belt2Hidden ? m.scale.set(1,1,1) : m.scale.set(0,0,0)
            } })
            this.belt2Hidden = !this.belt2Hidden
    }

    switchBoxes(){

        if( !this.shown ) return;
        
        if( this.currentBox === 1) {
            this.currentBox = 2
            // hide paperBox
            this.models[2].scale.set(1,1,1);
            // show cardboardBox
            this.models[1].scale.set(0,0,0);
            return;
            
        }if( this.currentBox === 2) {
            this.currentBox = 1
            // show paperBox
            this.models[1].scale.set(1,1,1);
            // hide cardboardBox
            this.models[2].scale.set(0,0,0);

        }
    }

    update() {
        this.models.forEach( (model) => 
        { if(model.animation) model.animation.mixer.update(this.time.delta * 0.001) }
        );
        if(this.movingTextures){ this.movingTextures.forEach( x => { x.offset.x -= 0.00085 })}
    }
}
