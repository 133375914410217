import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import EventEmitter from "./event-emitter";

export default class Resources extends EventEmitter {
    
    constructor(sources){
        super();

        this.sources = sources;

        // final loaded assets
        this.items = {};
        // number of things to be yet loaded
        this.toLoad = sources.length;
        //and already loaded 
        this.loaded = 0;

        this.setLoaders();
        this.startLoading();
    }

    setLoaders(){
        this.loaders = {};

        //gltf loader
        this.loaders.gltfLoader = new GLTFLoader();

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
      
        this.loaders.gltfLoader.setDRACOLoader(dracoLoader);

        // texture loaders
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
        this.loaders.rgbeLoader = new RGBELoader();
    }

    load (loaderKey, path, callback) {

        this.loaders[loaderKey].load(
            path,
            callback
        )
    }

    startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            let loaderKey;

            if(source.type === 'gltfModel') loaderKey = 'gltfLoader';
            if(source.type === 'texture') loaderKey = 'textureLoader';
            if(source.type === 'cubeTexture') loaderKey = 'cubeTextureLoader';
            if(source.type === 'hdr') loaderKey = 'rgbeLoader';
            
            this.load( loaderKey, source.path, (file) =>
            {  this.sourceLoaded(source, file)  }
            )
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file
        this.loaded++
        if(this.loaded >= this.toLoad)
        {
            this.trigger('ready')
        }
    }
}