import * as THREE from 'three';

import Sizes from "../utils/sizes";
import Time from "../utils/time";
import Camera from "./camera";
import Renderer from "./renderer";
import World from "../world/index";
import Resources from "../utils/resources";
import Debug from "../utils/debug";
import ModelTesting from "../utils/model-testing";
import HDRTesting from "../utils/hdr-testing";

import sources from "./sources.js"

let instance = null;

export default class Experience
{
    constructor(canvasContainer)    
    {

        if ( instance  ) return instance;
        instance = this;

        // Global access
        window.experience = this
        
        // Options
        this.canvas = canvasContainer;

        // Setup
        this.sizes = new Sizes();
        this.time = new Time();
        this.scene = new THREE.Scene();
        this.resources = new Resources(sources);
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.world = new World();

        //Debug elements
        this.debug = new Debug();
        this.modelTesting = new ModelTesting();
        this.hdrTesting = new HDRTesting();

        // Events
        this.sizes.on('resize', () => {
            this.resize();
        })
        this.time.on('tick', () => {
            this.update();
        })
        
    }

    resize ()
    {
        this.camera.resize();
        this.renderer.resize();
    }
    update ()
    {
        this.camera.update();
        this.world.update();
        this.renderer.update();

        this.modelTesting.update();
    }

    destroy()
    {
        this.time.off('tick');
        this.sizes.off('resize');

        //traverse the scene
        this.scene.traverse(
            (child) => {
                if( child instanceof THREE.Mesh)
                {
                    child.geometry.dispose();

                    for( const key in child.material)
                    {
                        const value = child.material[key];

                        if( value && typeof value.dispose === 'function'){
                            value.dispose();
                        }
                    }
                }
            }
        );
        this.camera.controls.dispose();
        this.renderer.instance.dispose();

        if(this.debug.active){
            this.debug.ui.destroy()
        }
    }
}