import * as THREE from "three";
import Experience from "../experience";

export default class Enviroment 
{
    constructor()
    {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.debug = this.experience.debug;

        //Debug 
        if( this.debug.active )
        {
            this.debugFolder = this.debug.ui.addFolder('environment');
        }

        this.initSunlight();
        this.initEnvironmentMap();
    }

    initSunlight(){
        this.sunLight = new THREE.DirectionalLight('#ffffff', 4)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(13,13, - 2.25)
        // this.scene.add(this.sunLight)

        //Debug 
        if( this.debug.active ){
            this.debugFolder.add(this.sunLight, 'castShadow');
        }
    }

    initEnvironmentMap()
    {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.37

        this.environmentMap.updateMaterial = () => 
        {
            this.scene.traverse((child) => {
                if(child instanceof THREE.Mesh && child.material && THREE.MeshStandardMaterial){
                    child.material.envMap = this.environmentMap.texture;
                    child.material.envMapIntensity = this.environmentMap.intensity;
                    child.material.needsUpdate = true;
                }
            })
        }

        this.setEnvironmentMapTexture(this.resources.items.environmentMapHDRTexture);


        //Debug 
        if( this.debug.active ){
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterial)
        }
    }

    setEnvironmentMapTexture(texture){

        this.environmentMap.texture = texture;
        this.environmentMap.texture.mapping = THREE.EquirectangularReflectionMapping;
        
        this.scene.environment = this.environmentMap.texture;

        this.environmentMap.updateMaterial();

    }

    
}