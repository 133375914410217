import Belt from "./index";

import Annotations from "./annotations";
import { raisedeckAnnotations } from "../../experience/annotation-data";

export default class Raisedeck extends Belt {

    constructor(args) {
        super(args);

        this.annotations = new Annotations(raisedeckAnnotations);
    }
}