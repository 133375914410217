import Belt from "./index";

import Annotations from "./annotations";
import { micropitchAnnotations } from "../../experience/annotation-data";

export default class Micropitch extends Belt {
    
    constructor(args) {
        super(args);

        this.annotations = new Annotations(micropitchAnnotations);
        this.setBeltsMovement();

        this.models.forEach(model => model.position.set(-0,-1,-0.4))


        // hidden by default
        this.toggle();

    }


    setBeltsMovement(){
        const belts = this.models[0].children[0].children[0].children[0].children[0].children
                .filter((c) => c.name.includes('Belt'));
        this.movingTextures = belts.map( b => b.children)
                .flat()
                .filter( c => c.material.map )
                .map( c => c.material.map );
        
    }


    
}