import * as THREE from "three";

import FileUploadTesting from "./file-upload-testing";


export default class ModelTesting extends FileUploadTesting   {

    constructor()
    {
        super("glb");

        this.time = this.experience.time;
    }


    cleanUp( reset = true ){
        if( reset ) FileUploadTesting.prototype.cleanUp.call(this);
        if( this.testingModel ) {
            this.scene.remove(this.testingModel);
            Object.keys(this.debugFolders).forEach(( folderName ) => this.debug.ui.removeFolder( folderName ));
            this.debugFolders = {};
      };
      
    }
  
  
    loadAsset(url) {
        this.resources.load('gltfLoader', url, (model) => { 
            this.setModel(model)
        })
    
  }
  
  setModel( model ) {
      this.cleanUp(false);
      this.testingModel = model.scene;
      
      this.testingModel.traverse((child) => {
        if ( child instanceof THREE.Mesh ) {
            child.castShadow = true;
        }
    })
      //add to the scene
      this.scene.add(this.testingModel);
      // debug UI
      if( this.debug.active ){
          this.debugFolders = {}; 
          Object.entries({
              "rotation" : [['y', 0, Math.PI * 2]] ,
              "position" : [['x', -10, 10], ['y', -10, 10], ['z', -10, 10]],
              "scale" : [['x', 0.01, 10], ['y', 0.01, 10], ['z', 0.01, 10]],
            }).forEach( ([name, arrArgs]) => {
            const folderName = `${ this.fileName } ${name}`;
            this.debugFolders[folderName] = this.debug.ui.addFolder(folderName);
            arrArgs.forEach( ( args ) => this.debugFolders[folderName].add(this.testingModel[name], ...args ));
          })

          if( model.animations ){

            this.animation = {}
            this.animation.mixer = new THREE.AnimationMixer(this.testingModel)
            this.animation.actions = {}

            model.animations.forEach((anim, i) => {
                const { name } = anim;
                this.animation.actions[name] = this.animation.mixer.clipAction(anim);
                if ( i === 0 ) this.animation.actions.current = this.animation.actions[name];
            })
            
            this.animation.actions.current.play();

            this.animation.play = (name) => {

                
                
                const newAction = this.animation.actions[name];
                const oldAction = this.animation.actions.current;
                newAction.reset();
                newAction.play();
                newAction.crossFadeFrom(oldAction, 1);
                this.animation.actions.current = newAction;

            };

            const animFolder = `${ this.fileName } animations`;
            this.debugFolders[animFolder] = this.debug.ui.addFolder(animFolder);

            const debugObject = model.animations.reduce( (obj, animClip) => {
                obj[`play ${animClip.name}`] = () => this.animation.play(animClip.name);
                return obj;
            }, {});

            Object.keys(debugObject).forEach((key) => this.debugFolders[animFolder].add(debugObject, key))   
            }
          }
      }  


    update() {
        if(this.animation && this.animation.mixer) this.animation.mixer.update(this.time.delta * 0.001);
    }
  
}