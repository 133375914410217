export default [
    // {
    //     name: 'environmentMapTexture',
    //     type: 'cubeTexture',
    //     path:
    //     [
    //         'textures/environmentMap/px.jpg',
    //         'textures/environmentMap/nx.jpg',
    //         'textures/environmentMap/py.jpg',
    //         'textures/environmentMap/ny.jpg',
    //         'textures/environmentMap/pz.jpg',
    //         'textures/environmentMap/nz.jpg'
    //     ],
    // },
    {
        name: 'environmentMapHDRTexture',
        type: 'hdr',
        path: 'textures/hdr/hdr.hdr'
    },
    {
        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/dirt/color.jpg'
    },
    {
        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/dirt/normal.jpg'
    },
    {
        name: 'raisedeckModel',
        type: 'gltfModel',
        path: 'models/Raisedeck/Raisedeck.glb'
    },
    {
        name: 'micropitchModel',
        type: 'gltfModel',
        path: 'models/Micropitch/conveyor_belt.glb'
    },
    {
        name: 'micropitchCans',
        type: 'gltfModel',
        path: 'models/Micropitch/cans.glb'
    },
    {
        name: 'micropitchBox',
        type: 'gltfModel',
        path: 'models/Micropitch/cardbox.glb'
    },
    {
        name: 'cardboardBoxModel',
        type: 'gltfModel',
        path: 'models/Raisedeck/Raisedeck_Box_Cardboard.glb'
    },
    {
        name: 'plasticBoxModel',
        type: 'gltfModel',
        path: 'models/Raisedeck/Raisedeck_Box_Plastic.glb'
    },
    {
        name: 'proxy1',
        type: 'gltfModel',
        path: 'models/Raisedeck/Raisedeck_Proxy_1_6.glb'
    },
    {
        name: 'proxy2',
        type: 'gltfModel',
        path: 'models/Raisedeck/Raisedeck_Proxy_2_2.glb'
    },
    {
        name: 'circleTexture',
        type: 'texture',
        path: 'img/circle.png'
    }
]