import * as THREE from 'three';
import Experience from "./index";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'

const DEFAULT_POSITION = [
    2.5785141110110357,
    1.8547421689163897,
    2.350442680279611
]
const DEFAULT_TARGETS = [[
    -0,
    0,
    -0.45
],[
    1,
    0.9,
    0.3
]];


let TARGET_INDEX = 1;

export default class Camera
{
    constructor(){
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        

        this.setInstance();
        this.instance.updateProjectionMatrix();
        this.setControls();
    }


    setInstance(){
        this.instance = new THREE.PerspectiveCamera( 50, this.sizes.width / this.sizes.height, 0.1, 100 );
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.position.set( ...DEFAULT_POSITION );
        this.scene.add(this.instance);
    }

    setControls() {
        this.controls = new OrbitControls( this.instance, this.canvas );
        this.controls.enableDamping = true ;
        this.controls.target.set(...DEFAULT_TARGETS[TARGET_INDEX])
        this.controls.enablePan = true ;
        // this.controls.maxDistance = 4.5;
        // this.controls.maxPolarAngle  = Math.PI * 0.35;
        // this.controls.minAzimuthAngle  = Math.PI * 0.05;
        // this.controls.maxAzimuthAngle  = Math.PI * 0.45;

    }

    toggle() {
        
        if(TARGET_INDEX === 0) {TARGET_INDEX = 1} else {TARGET_INDEX = 0}

        this.controls.target.set(...DEFAULT_TARGETS[TARGET_INDEX])
        console.log('togglie togl', DEFAULT_TARGETS[TARGET_INDEX]);
    }

    flyTo(tar, pos) {

        

        const target = tar || DEFAULT_TARGETS[TARGET_INDEX];
        const position = pos || DEFAULT_POSITION;
        
        new TWEEN.Tween(this.controls.target)
            .to(
                {
                    x: target[0],
                    y: target[1],
                    z: target[2],
                },
                500
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
        new TWEEN.Tween(this.instance.position)
            .to(
                {
                    x: position[0],
                    y: position[1],
                    z: position[2],
                },
                500
            )
            .easing(TWEEN.Easing.Cubic.Out)
            .start()


    }
    

    resize(){
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix();
    }

    update(){
        this.controls.update();
        TWEEN.update();
    }

    
}