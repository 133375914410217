import * as THREE from "three"
import Experience from "../experience";
import Environment from "./environment.js";
import Belt from "./belt";
import Micropitch from "./belt/micropitch";
import Raisedeck from "./belt/raisedeck";
import Floor from "./floor";
import Annotations from "./belt/annotations";

import { raisedeckAnnotations, micropitchAnnotations } from "../experience/annotation-data";


export default class World {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        this.resources.on('ready', () => {
             //setup
            this.environment = new Environment();
             
            this.raiseDeck = new Raisedeck(['raisedeckModel', 'cardboardBoxModel', 'plasticBoxModel', 'proxy1', 'proxy2']);
            this.microPitch = new Micropitch(['micropitchModel', "micropitchBox", "micropitchCans" ], true, false);
            
        })
    }

    toggle(){
        this.raiseDeck.toggle();
        this.microPitch.toggle();

        this.experience.camera.toggle();
    }

    switchBoxes(){
        this.raiseDeck.switchBoxes();
        this.microPitch.switchBoxes();
    }

    update() 
    {
        if ( this.raiseDeck ){ this.raiseDeck.update();}
        if ( this.microPitch ){ this.microPitch.update();}
    }
}