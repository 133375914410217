import EventEmitter from "./event-emitter.js";
export default class Sizes extends EventEmitter 
{
    constructor()
    {
        super();
        const x = window.innerWidth, y = window.innerHeight;
        this.setWidthHeightPixelRatio = this.setWidthHeightPixelRatio.bind(this);
        this.doResize = this.doResize.bind(this);
        this.setWidthHeightPixelRatio( x, y );
        // Resize event
        window.addEventListener('resize', this.doResize);   
        
    }

    doResize() {
        this.setWidthHeightPixelRatio( window.innerWidth, window.innerHeight );
        this.trigger('resize');
    }

    setWidthHeightPixelRatio(x, y) {
        this.width = x;
        this.height = y;
        this.pixelRatio = Math.min(window.devicePixelRatio, 2);
    }
}