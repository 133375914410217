import * as THREE from "three";
import Experience from "../../experience";

import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'


const uspContainer = document.querySelector(".usp");
const uspTitle = document.querySelector(".usp .title");
const uspDesc = document.querySelector(".usp .description");





export default class Annotations 
{
    constructor( data )
    {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        this.data = data;

        this.annotationLabels = [];
        this.annotationSprites = [];

        this.annotations = {};

        this.shown = true;

        this.setSprites();
        this.setRaycaster();
    }

    hideAllAnnotations( besides = "" ){
        Object.values(this.annotations).forEach((l, i) => {
            
            if(l.id !== besides){
            const { annotationLabel, annotationSprite } = l;
            annotationLabel.element.style.opacity = 0
            annotationSprite.visible =  false 
            }
            
        })
    }
    
    showAllAnnotations( besides = "" ){
        Object.values(this.annotations).forEach((l, i) => {
            
            if(l.id !== besides){
            const { annotationLabel, annotationSprite } = l;
            annotationLabel.element.style.opacity = 1
            annotationSprite.visible =  true 
            }
            
        })
    }

    toggle(){

        this.shown ? this.hideAllAnnotations() : this.showAllAnnotations() ;

        this.shown = !this.shown;
    }

    setSprites() {
        this.data.forEach(({ name, description, position, id, ...x }) => {
            const annotationSpriteMaterial = new THREE.SpriteMaterial({
                map: this.resources.items.circleTexture,
                depthTest: false,
                depthWrite: false,
                sizeAttenuation: false,
            })
            const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
            annotationSprite._customId = id;
            annotationSprite.scale.set(.018,.018,.018)
            annotationSprite.position.set(...position)
            this.scene.add(annotationSprite)
            this.annotationSprites.push(annotationSprite);
    
            const annotationDiv = document.createElement('div')
            annotationDiv.className = 'annotationLabel'
            annotationDiv.innerHTML = id;
            const annotationLabel = new CSS2DObject(annotationDiv);
            annotationLabel.position.set(...position);
            if( this.hiddenByDefault ) annotationLabel.element.style.opacity = 0;
            this.scene.add(annotationLabel)
            this.annotationLabels.push(annotationLabel);
            if(x.yellow){ 
                annotationDiv.classList.add('yellow')
             };


            this.annotations[id] = { id, name, position, description,
                 annotationLabel, annotationSprite, ...x };
    
        })
        
        
    }

    setRaycaster(){
        this.raycaster = new THREE.Raycaster()
        this.experience.canvas.addEventListener( 'pointerup', this.clicked.bind(this))
    }

    clicked (event) {

        console.log('a', Object.values(this.experience.camera.instance.position));
        
        
        if(!this.shown || this.selected) return;
        const renderer = this.experience.renderer.instance;
        const camera = this.experience.camera.instance;
        this.raycaster.setFromCamera(
            {
                x: (event.clientX / renderer.domElement.clientWidth) * 2 - 1,
                y: -(event.clientY / renderer.domElement.clientHeight) * 2 + 1,
            },
            camera
        )
    
        const intersects = this.raycaster.intersectObjects(this.annotationSprites, true);
        if (intersects.length > 0) {
            const { _customId: id } = intersects[0]?.object;
            this.selected = this.annotations[id] ;
        } else { 
            this.selected = undefined
        }

        

        
        uspContainer.style.display = this.selected ? "block" : "none";
     
        uspTitle.innerHTML = this.selected?.name || '';
        uspDesc.innerHTML = this.selected?.description || '';

        if( this.selected ) {
            this.hideAllAnnotations(this.selected.id);
            this.experience.camera.flyTo(this.selected.position, this.selected.lookFrom);
        } else {
            this.showAllAnnotations();
        }
    }

    deselect() {
        this.selected = undefined;
        this.experience.camera.flyTo(null);
        if( this.shown ) this.showAllAnnotations();
        uspContainer.style.display = "none";
    }

    toggleDescription(){

    }


    update(){
        this.animation.mixer.update(this.time.delta * 0.001)
    }
}





