import './style.scss'
import Experience from "./experience";

let MODEL = 1;

let raisedeckSrc = 'models/Raisedeck/Raisedeck.glb';
let micropitchSrc = 'models/Micropitch/conveyor_belt.glb';

function main () {
    
    const canvasContainer = document.querySelector('canvas.webgl');
    const ex = new Experience(canvasContainer);
    const modelViewer = document.querySelector("model-viewer");

    
    const toggleBeltRadius1 = document.querySelector('#input-7');
    const toggleBeltRadius2 = document.querySelector('#input-8');
    toggleBeltRadius1.addEventListener('change', () => { 
        ex.world.raiseDeck.toggleBelt1();
     })
     toggleBeltRadius2.addEventListener('change', () => { 
        ex.world.raiseDeck.toggleBelt2();
     })

     document.querySelectorAll('input[name^="radio-group"]').forEach((x) => x.addEventListener("click", (e) => {
       MODEL === 1 ? ex.world.raiseDeck.switchBoxes() : ex.world.microPitch.switchBoxes();
    }));

    const toggleModelBttns = document.querySelectorAll('.toggle-model');
    toggleModelBttns.forEach((bttn, i) => bttn.addEventListener('click', () => {
        console.log("click", MODEL);
        if(MODEL === i) return; 
        MODEL = i;
        ex.world.toggle();
        console.log("toggled", MODEL);
        bttn.classList.add('active');
        toggleModelBttns[i === 1? 0 : 1].classList.remove('active');
       
        document.querySelectorAll(`.model-${[i === 1? 0 : 1]}`)
            .forEach(x => x.style.display = "none");
        document.querySelectorAll(`.model-${[i === 1? 1: 0]}`)
            .forEach(x =>{  x.style.display = "block"});
        
        if (MODEL === 1){ modelViewer.src = raisedeckSrc }
        if (MODEL === 0){ modelViewer.src = micropitchSrc }
    }))

    document.querySelector(".usp .x").addEventListener('click', () => {
        ex.world.microPitch.annotations.deselect()
        ex.world.raiseDeck.annotations.deselect()
    })
    

}

window.onload = main;
