import * as THREE from "three";
import * as dat from 'dat.gui';

dat.GUI.prototype.removeFolder = function(name) {
    var folder = this.__folders[name];
    if (!folder) {
      return;
    }
    folder.close();
    this.__ul.removeChild(folder.domElement.parentNode);
    delete this.__folders[name];
    this.onResize();
  }

export default class Debug {
    constructor() {
        this.active = window.location.hash === "#debug";

        if( this.active )
        {
            this.ui = new dat.GUI();
            document.querySelector(".drop-area-container").style.display = "flex";
        }
    }

    fillDebugFolder( folder, debugObject ){
        Object.keys(debugObject).forEach((key) => folder.add(debugObject, key))
    }
}