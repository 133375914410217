import * as THREE from "three";

import FileUploadTesting from "./file-upload-testing";


export default class HDRTesting extends FileUploadTesting   {

    constructor()
    {
        super('hdr');
    }


    cleanUp( reset = true ){
        if( reset ) FileUploadTesting.prototype.cleanUp.call(this);
        if( !this.initialTexture ) this.initialTexture = this.experience.world.environment.environmentMap.texture;
        if( this.hdr ) {
            if( reset ) {
                this.experience.world.environment.setEnvironmentMapTexture( this.initialTexture );
            }
            this.hdr = null;
      };
    }
  
  
    loadAsset(url) {
        this.resources.load('rgbeLoader', url, (texture) => { 
            this.setHDR(texture);
        })
  }
  
  setHDR( hdr ) {
      
      this.cleanUp(false);
      this.hdr = hdr;

      if( !this.initialTexture ) this.initialTexture = this.experience.world.environment.environmentMap.texture;

      this.experience.world.environment.setEnvironmentMapTexture( hdr );
    
  }
  
}