import { FrontSide } from "three";
import Experience from "../experience";

window.addEventListener("dragover",function(e){
    e = e || event;
    e.preventDefault();
  },false);
  window.addEventListener("drop",function(e){
    e = e || event;
    e.preventDefault();
  },false);

const divDropAreaContainer = document.createElement("div");
divDropAreaContainer.classList.add('drop-area-container');
document.body.appendChild(divDropAreaContainer);

  /**
   *  <!-- <div class="drop-area-container">
    <div class="drop-area" id="drop-area-glb">
        <form class="my-form">
          <p>Add GLB to the scene by dragging and dropping it onto the dashed region, or...</p>
          <input type="file" class="fileElem"  id="fileElem-glb">
          <label class="button" for="fileElem-glb">Select file</label>
          <div class="button rmv" id="remove-glb" for="fileElem">Remove file</div>
        </form>
        <div id="file-name-glb"></div>
    </div>
    <div class="drop-area" id="drop-area-hdr">
        <form class="my-form">
          <p>Set envMap in the scene by dragging and dropping HDR onto the dashed region, or...</p>
          <input type="file"  class="fileElem" id="fileElem-hdr">
          <label class="button" for="fileElem-hdr">Select file</label>
          <div class="button rmv" id="remove-hdr" for="fileElem">Remove file</div>
        </form>
        <div id="file-name-hdr"></div>
        
    </div>
    </div>
   */

export default class FileUploadTesting {

    dropArea = null;
            
    removeBttn = null;

    selectBttn = null;

    fileFormat = '';

    constructor( fileFormat )
    {
        
        this.active = window.location.hash === "#debug";
        this.experience = new Experience();
        this.debug = this.experience.debug;
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;

        this.fileFormat = fileFormat;

        if( this.active ) {
            this.setUpHTMLElements();
            this.setUpEventHandlers();
        }

    }

   
    setUpHTMLElements(){

        this.dropArea = document.createElement('div');
        this.dropArea.classList.add('drop-area')
        this.dropArea.id = `drop-area-${this.fileFormat}`;

        this.removeBttn = document.createElement('div');
        this.removeBttn.id = `remove-${this.fileFormat}`;
        this.removeBttn.innerHTML = "Remove file";
        this.removeBttn.classList.add('button');
        this.removeBttn.classList.add('rmv');


        const input = document.createElement('input');
        input.type = "file";
        input.classList.add("fileElem");
        input.id = `fileElem-${this.fileFormat}`;

        this.selectBttn = document.createElement('label');
        this.selectBttn.classList.add('button');
        this.selectBttn.setAttribute("for", `fileElem-${this.fileFormat}`);
        this.selectBttn.innerHTML = "Select file";

        this.fileNameDiv = document.createElement('div');
        this.fileNameDiv.id = `file-name-${this.fileFormat}`;
        this.fileNameDiv.innerHTML = "No file selected";

        const form = document.createElement('form');
        form.classList.add('my-form');

        const paragraph = document.createElement('p');
        paragraph.innerHTML = `Add ${this.fileFormat.toUpperCase()} to the scene by dragging and dropping it onto the dashed region, or..`
        
        //append to HTML document
        form.appendChild(paragraph);
        form.appendChild(input);
        form.appendChild(this.selectBttn);
        form.appendChild(this.removeBttn);

        this.dropArea.appendChild(form);
        this.dropArea.appendChild(this.fileNameDiv);
        divDropAreaContainer.appendChild(this.dropArea);

        
    }

    setUpEventHandlers(){
        // Prevent default drag behaviors
        let arr = ["dragenter", "dragover", "dragleave", "drop"];
        arr.map((eventName) => {
            this.dropArea.addEventListener(eventName, () => { this.preventDefaults.bind(this)}, false)   
            document.body.addEventListener(eventName, () => { this.preventDefaults.bind(this)}, false)
        });
       
        // Highlight drop area when item is dragged over it
        arr = ["dragenter", "dragover"]; arr.map((eventName) => {
            this.dropArea.addEventListener(eventName, this.highlight.bind(this), false)
        })

        arr = ["dragleave", "drop"]; arr.map((eventName) => {
            this.dropArea.addEventListener(eventName, this.unhighlight.bind(this), false)
        })

        // Handle dropped files
        this.dropArea.addEventListener('drop', this.handleDrop.bind(this), false);
        const select = (files) => this.handleSelect(files);
        document.getElementById(`fileElem-${this.fileFormat}`).onchange = function(){
         
            select(this.files);
        };
             
        this.selectBttn.onchange = function(){
            
            select(this.files);
        };
             
        this.removeBttn.addEventListener('click', this.handleRemove.bind(this), false);

     }

     handleFiles(files) {
         
        files = [...files]
    
       const fileName = files[0].name;
    
        if(!fileName.endsWith(this.fileFormat)) { 
            alert(`Only ${this.fileFormat} is allowed!`);
            return;
         }

         this.fileName = fileName;

         this.printFileName();
        const url = URL.createObjectURL(files[0]);
    
        this.loadAsset(url);
    
  }

  printFileName(){
    this.fileNameDiv.innerText = this.fileName || "No file selected";
  }

    preventDefaults (e) 
    {     
        e.preventDefault()
        e.stopPropagation()
    }
  
    highlight(e) 
    {
        this.dropArea.style.background = "rgba(0, 0, 0, 0.9)"
    }
  
    unhighlight(e) 
    {
        this.dropArea.style.background = "rgba(0, 0, 0, 0.6)"
    }

    handleSelect(files)
    {
        this.handleFiles(files)
    }
  
    handleDrop(e) 
    {
        var dt = e.dataTransfer
        var files = dt.files
  
        this.handleFiles(files)
    }

    handleRemove(){ this.cleanUp()  }

    cleanUp(){
        this.fileName = '';
        this.printFileName();
    }

  
}