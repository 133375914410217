export const raisedeckAnnotations = [
    {
        name: 'Continuous surface',
        description: 'Continuous surface that prevents the case puncturing sometimes associated with roller conveyors',
        id: '1',
        position: [1.29,.9,.5],
        lookFrom: [
            1.6046930778075978,
            1.1849921423510885,
            0.7306267465813743
        ]
    },
    {
        name: 'Reliable product placement',
        description: 'Better product placement without misalignment',
        id: '2',
        position: [0.85,0.9,-0.01],
        lookFrom: [
            1.3628367644303712,
            1.4691817848864606,
            0.07122536391215041
        ]
    },
    {
        name: "Better process control",
        description: "Steadier flow of goods that delivers better control of the process",
        id: '3',
        position: [1.17,0.9,.15],
        lookFrom: [
            1.4783912616889294,
            1.368333458949493,
            0.2352282864015709
        ]
    },
    {
        name: "Up to 5 times floor space saving",
        description: "The M2592 Radius Flush Grid Raised Deck is the new radius 1” pitch belt that permits a significant reduction in the conveyor system footprint. It uses 3 to 5 times less conveyor floor space than needed for traditional solutions",
        id: "4",
        position: [1,0.85,0.2],
        lookFrom: [
            1.0694614889611878,
            1.0762442382910922,
            0.6385625811402833
        ]
    },
    {
        name: "Supports wide range of product sizes",
        description: "The raised deck surface allows to convey products with a wide range of sizes - from small boxes to wide pans - without interference from lateral wearstrips",
        id: "5",
        position: [1.4,0.89,0.93],
        lookFrom: [
            1.7000000961293475,
            1.0639940862903607,
            1.0931267090663406
        ]
    },
    {
        name: "Easy conveyor construction and maintenance",
        description: "Conveyor construction and maintenance are easier than for other radius belt solutions, and the belt uses standard L-shape wearstrips",
        id: "6",
        position: [1.27,0.89,0.7],
        lookFrom: [
            1.7571631371640377,
            1.274860866602479,
            1.3772333319426084
        ]
    },
    {
        name: "Longer conveyor with fewer drives needed",
        description: "The belt's superior strength allows to design longer conveyors and reduce the number of drives and product transfers between consecutive conveyors. The M2592 is one of the most robust belts on the market for radius applications",
        id: "7",
        position: [1.3,0.87,.98],
        lookFrom: [
            1.4985527095023445,
            1.162650975582086,
            1.4939710328343379
        ]
    },
    {
        name: "Low-noise solution",
        description: "The Habasit M2592 plastic modular belt is much quieter than traditional roller or chain solutions. This creates a more comfortable production environment for line operators",
        id: "8",
        position: [0.45,0.9,-0.01],
        lookFrom: [
            1.0624301610640599,
            1.2251652147557057,
            0.1254767266765787
        ]
    },{
        name: "Low-noise solution",
        description: "The Habasit M2592 plastic modular belt is much quieter than traditional roller or chain solutions. This creates a more comfortable production environment for line operators",
        id: "9",
        position: [1.25,0.9,0.89],
        lookFrom: [
            1.335456641837927,
            1.3511829652903327,
            1.4295520018432548
        ]
    },
];

export const micropitchAnnotations = [
    {
        name: 'Suitable for tight transfers',
        description: '50% lower minimum transfer distance than conventional  plastic modular belts',
        id: '1',
        position: [0.7,0.35,-0.5],
        lookFrom: [
            1.0082399399238713,
            0.845807174330799,
            0.10057907779598153
        ]
    },{
        name: 'Reliable product positioning',
        description: 'Thanks to the high grip surface that keeps the product in place even on incline applications, the M0870 belt provides accurate positioning in combination with tight transfers, and avoids tracking issues',
        id: '2',
        position: [0.6,0.55,-0.55],
        lookFrom: [
            0.9019080442830665,
            1.0647665356942049,
            -0.04401235283391197
        ]
    },{
        name: 'Improved productivity',
        description: 'The high lateral stiffness of the belt prevents time-loss due to belt mistracking, and results in less maintenance and reduced downtime, while increased acceleration and higher operating speeds supporting productivity',
        id: '3',
        position: [-0.4,0.2,-0.8],
        lookFrom: [
            0.5975617762649506,
            0.7906782708306468,
            0.20792647468545306
        ]
    },{
        name: 'Improved hygiene and food safety',
        description: 'The M0870 HighGrip‘s hygienic design and high-quality plastic material prevent parts being released into the food being processed, improving the hygiene and food safety of your processing operations.',
        id: '4',
        position: [0.6,0.4,-0.2],
        lookFrom: [
            1.0228244721644044,
            0.7745505046022427,
            0.1247376252611509
        ]
    },{
        name: 'High grip surface design',
        description: 'Grip top with grooved surface for high friction and less grip reduction by dirt and dust',
        id: '5',
        position: [0.45,0.45,-0.3],
        lookFrom: [
            0.7238849288827636,
            1.1409010621177664,
            0.1756771095554877
        ]
    },{
        name: 'Snap fit rod system',
        description: 'Snap Fit rod retaining system. Rod diameter 3 mm (0,12’)',
        id: '6',
        position: [0.7,0.35,-1.1],
        lookFrom: [
            1.3569700316700577,
            0.2797482242813578,
            -1.8512238565964738
        ]
    },{
        name: 'Knife edge suitable',
        description: 'Runs on knife edge diameter 6 mm (0.24”)',
        id: '7',
        position: [-0.62,-0.1,-1],
        lookFrom: [
            0.4566431889890967,
            0.8469247391494832,
            -1.7330256207482808
        ]
    },{
        name: 'Unique design',
        description: "The proven and reliable high grip M0870 0.3' pitch belt is unique on the market",
        id: '8',
        position: [0.45,0.3,-0.1],
        lookFrom: [
            2.1463910922475864,
            2.726460566768972,
            1.540100192610449
        ]
    },{
        name: 'Habasit Saniclip for easy belt sanitation',
        description: 'Saniclip is the quick belt opening system for HabasitLINK modular belts, an innovative device for quick belt assembly and easy belt sanitation',
        id: '9',
        position: [-0.3,0.2,-0.4],
        lookFrom: [
            -0.11218117376149364,
            0.2781344559874919,
            -0.07085412650476675
        ],
        yellow: true,
    },{
        name: 'Supports frequent sanitation needs',
        description: 'Saniclip allows simple rod installation and removal in applications where frequent belt sanitation is required',
        id: '10',
        position: [-0.5,0.2,-0.6],
        yellow: true,
        lookFrom: [
            -1.078686748769274,
            0.34939436186784445,
            -1.7191661402600673
        ]
    },{
        name: 'Multiple Habasit Saniclips possible',
        description: 'Multiple Habasit Saniclips can be installed in a single belt to allow easy opening at several locations',
        id: '11',
        position: [-0.2,0.2,0.16],
        yellow: true,
        lookFrom: [
            0.381375992978084,
            0.8721973943608905,
            0.9670894317734379
        ]
    },{
        name: 'Quick and tool-free installation',
        description: 'Extracted or installed in seconds, no tools required and skilled personnel not required',
        id: '12',
        position: [-0.1,0.1,0],
        yellow: true,
        lookFrom: [
            0.24438307559637892,
            0.49780133587530895,
            1.011746147537262
        ]
    },{
        name: 'Non slip surface',
        description: 'Non slip surface for easy removal and installation in wet or fatty environments',
        id: '13',
        position: [-0.05, 0.05,0.2],
        yellow: true,
        lookFrom: [
            0.24308710141710127,
            0.37474913579527724,
            0.6654211561686312
        ]
    },{
        name: 'Quick and tool-free installation',
        description: 'Habasit Saniclip solves the problem of time consuming belt removal and eliminates the need for extraction tools',
        id: '14',
        position: [-0.42,0.2,-0.25],
        yellow: true,
        lookFrom: [
            -1.188247187787703,
            0.2518463247421012,
            0.4230683322244116
        ]
    },{
        name: 'No risk of component losses',
        description: 'Plug / lever and rod are combined to prevent accidental loss of components',
        id: '15',
        position: [0.15,0.12,0.15],
        yellow: true,
        lookFrom: [
            0.8509204302907069,
            0.8678340588448129,
            0.4987949242789934
        ]
    },{
        name: 'Multiple options available',
        description: 'Available for a wide range of Plastic Modular Belts',
        id: '16',
        position: [-0.2,0.2,-0],
        yellow: true,
        lookFrom: [
            -0.2230293141156211,
            0.8548088898912851,
            0.5540971403463333
        ]
    }
];
